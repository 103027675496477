html, body {
  height: 100%;
  margin: 0;
}

.App {
  text-align: left;
  height: 100%;
  display: flex;
  flex-direction: column;
}